<template>
  <div class="thelogo">
    <div class="thelogo__color navdesktop__messagetext">
      html5-ads.com
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.thelogo__color {
  white-space: nowrap;
  color: white;
}

.thelogo {
  &.isblack {
    .thelogo__color {
      color: black;
    }
  }
}
</style>
